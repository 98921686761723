<template>
  <div class="backHome">
    <div class="title">
      <div class="titleItem">{{ modular }}</div>
      <div class="tabBox">
        <div class="tab_control">
          <el-tabs v-model="activeName" type="card" closable="true" @tab-click="changePage" class="navStyle">
            <el-tab-pane v-for="item in tabData" :key="item.title" :name="item.label" :label="item.title"></el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="titleRight" @click="clearFun">
        <div class="menuItem">
          <!-- <router-link to="/menuSettings">权限管理</router-link>   v-if="roleUser.userName == 'admin'"-->
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link infoStyle"> {{ userInfo }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item command="system" v-if="userInfo.userName == 'admin'">系统管理</el-dropdown-item> -->
              <el-dropdown-item command="out">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="menuItem" @click="removeRole">
          <router-link :to="`${screenRouter}`">数据大屏</router-link>
        </div>
      </div>
    </div>
    <div class="conter">
      <div class="nav">
        <el-row class="tac">
          <el-col :span="12" v-if="navData.length != 0">
            <el-menu
                @select="handleSelect"
                :default-active="indexNav"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                :index="item.label + '_' + item.path"
                @close="handleClose"
                v-for="(item, i) in navData"
                :key="i"
            >
              <el-submenu v-if="item.children" :index="item.label + '_' + i">
                <!-- item.label -->
                <template slot="title">
                  <!-- <i class="el-icon-location"></i> -->
                  <img src="@/assets/loginImg/security.png" alt=""/>
                  <span class="lavelStyle">{{ item.label }}</span>
                </template>
                <div class="" v-for="(items, r) in navData[i].children" :key="r">
                  <!--  v-if="navData[i].children[r].children"-->
                  <el-submenu class="menuTwo" :index="items.label + '_' + r" v-if="navData[i].children[r].children">
                    <template slot="title">{{ items.label }}</template>
                    <el-menu-item class="menuTwoItem" :index="z.label + '_' + z.path"
                                  v-for="(z, p) in navData[i].children[r].children" :key="p">{{ z.label }}
                    </el-menu-item>
                  </el-submenu>
                  <!--  -->
                  <el-menu-item v-else class="menuTwoItems" :index="items.label + '_' + items.path">{{
                      items.label
                    }}
                  </el-menu-item>
                </div>
                <!-- <div v-else>
                  <el-menu-item :index="item.label + '_' + item.path" v-for="(item, p) in navData[i].children" :key="p">{{ item.label }}</el-menu-item>
                </div> -->
              </el-submenu>
              <el-menu-item v-else :index="item.label + '_' + item.path">
                <!-- <i class="el-icon-setting"></i> -->
                <span class="single" slot="title">{{ item.label }}</span>
              </el-menu-item>
            </el-menu>
          </el-col>
          <span class="found" v-else>暂无数据 </span>
        </el-row>
      </div>
      <div class="rightBoxTwo" v-if="navData.length != 0">

        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { historyRouter } from '../router';
export default {
  props: {},
  data() {
    return {
      screenRouter: '',
      tole: true,
      activeName: '',
      tabData: [],
      indexNav: '',
      screenWidth: document.body.clientWidth,
      navIndex: 0,
      headTitle: '',
      // navData: dataPath,
      navData: [],
      userInfo: '',
      modular: '后台管理系统',
      userRoluPath: [],
    };
  },

  watch: {
    $route: {
      handler: function (route) {

      },
    },
  },
  beforeMount() {
    this.$get('/rights/getRightById', {username: window.localStorage.getItem('user'), type: 0}).then((res) => {
      let data = [];
      if (res.data.state == 'success' && res.data.datas.length) {
        //data = res.data.datas[0].children ? res.data.datas[0].children : res.data.datas[0];
        data = res.data.datas;
        window.localStorage.setItem('userPath', JSON.stringify(data));
        this.$store.commit('setUserPath', data);
        historyRouter(data);
      } else {
        window.localStorage.setItem('userPath', JSON.stringify(data));
        this.$store.commit('setUserPath', data);
        historyRouter(data);
      }

      let userPath = this.$store.state.userPath;
      let index = window.localStorage.getItem('marking');
      // 避免后台重复
      this.navData = [];
      if (!userPath.length) {
        return
      }
      this.navData = userPath;
      this.defaultPath();
    });
  },
  mounted() {
    this.screenRouter = window.localStorage.getItem('screenRouter');
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth;
        //that.screenWidth = window.screenWidth;
        this.screenWidth = window.screenWidth;
      };
    };
    // 用户信息
    if (this.$store.state.user) {
      this.userInfo = this.$store.state.user;
    }
    // //console.log(this.navData);
    // this.userInfoRolu();
  },
  methods: {
    // 点击触发选项卡切换事件
    changePage(e) {
      this.$router.push(e.name);
      this.indexNav = e.label + '_' + e.name;
    },
    isIn(item) {
      for (var i = 0; i < this.tabData.length; i++) {
        if (this.tabData[i].label === item.label) {
          return true;
        }
      }
      return false;
    },
    // 获取路径
    async userInfoRolu() {
      let id = this.$store.state.roluUser.role;
      if (!id) {
        return;
      }
      let { data } = await this.$get('/rights/getRoleRight', { id });
      this.userRoluPath = data.data.list2;
    },

    // 判断路径
    async judgePath(path) {
      let _this = this;
      let id = _this.$store.state.roluUser.role;
      if (!id) {
        return;
      }
      let { data } = await _this.$get('/rights/getRoleRight', { id });
      _this.userRoluPath = data.data.list2;

      let paths = _this.$route.meta.name;
      if (paths != 'menu') {
        // _this.userRoluPath.forEach((item) => {
        //   if (item.indexOf(path)!=1) {
        //     _this.$router.push('/404');
        //   }
        // });
        //路径不为该用户所拥有
        let roluPath = _this.userRoluPath.findIndex((item) => item == path);
        // if (roluPath == '-1') return _this.$route.push('/404');
        if (roluPath == '-1') return _this.$router.push('/404');
      }
    },
    backName() {
      this.modular = '后台管理系统';
    },
    defaultPath() {
      let keepPathData = window.localStorage.getItem('path'),
          keepPath;
      if (keepPathData) {
        console.log("keepPathData", keepPathData)
        keepPath = keepPathData.split('_')[1];
        this.$router.push(keepPath);
        this.indexNav = keepPathData;
        return;
      }


      if (!this.$route.query.path) {
        console.log()("path1", this.$route.query.path)
        let url = this.navData[0].children ? this.navData[0].children[0] : this.navData[0];
        let url2 = url.children ? url.children[0] : url;
        this.$router.push(url2.path);
        this.indexNav = url2.label + '_' + url2.path;
      } else {
        console.log("path2", this.$route.query.path)

        this.$router.push(this.$route.query.path);
        this.indexNav = this.$route.query.label + '_' + this.$route.query.path;
      }
    },

    handleClick(tab, event) {
      //console.log(tab, event);
    },
    handleOpen(key, keyPath) {
      //   //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      // //console.log(key);
      this.headTitle = key.split('_')[0];
      //   导航存缓存
      window.localStorage.setItem('path', key);
      //   this.$router.push({
      //     path: key,
      //     params: { data: 'query' },
      //   });
      const nevMsg = key.split('_');
      this.$router.push(nevMsg[1]);
      const objMsg = {
        label: nevMsg[1],
        title: nevMsg[0],
      };
      if (this.navIndex > -(this.tabData.length - 12)) {
        this.navIndex -= 1;
      }
      if (!this.isIn(objMsg)) {
        this.tabData.push(objMsg);
      }
      this.activeName = nevMsg[1];
      this.indexNav = key;
    },
    clearFun() {
      window.localStorage.removeItem('path');
    },
    // 用户跳转
    // userJump() {},
    handleCommand(command) {
      let url = '';
      let path = this.$route.meta;
      if (command == 'out') {
        url = '/login';
        window.localStorage.clear();
        this.$router.push(url);
      }

      //判断重复点击相同路由
      if (path.name != 'menu') {
        this.$router.push(url);
      }
    },
    // 重置系统状态
    removeRole() {
      //   window.localStorage.setItem('roleIndex', false);
      //   this.$store.commit('setRoleIndex', false);
    },
    beforeRouteEnter(to, from, next) {

      next();
    },
  },
};
</script>
<style scoped lang="less">
.backHome {
  background: #f0f2f5;
  height: 100vh;
  overflow: hidden;
}
.headline {
  height: 5vh;
  line-height: 5vh;
  border-bottom: 1px solid #ccc;
  text-indent: 1em;
  background: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  h3 {
    font-size: 2vh;
  }
}
.nav {
  width: 14.37vw;
  height: 94.8vh;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  background: #001529;
}
.nav::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.title {
  width: 100%;
  height: 5.15vh;
  background: #00284d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .titleRight {
    margin-right: 2vh;
    display: flex;
    // width: 6vw;
    justify-content: space-between;
    .menuItem {
      margin-right: 2vh;
    }
  }
  .titleRight a {
    color: #fff !important;
    text-decoration: none;
    font-size: 1.3vh;
  }

  .titleItem {
    font-size: 2vh;
    font-family: YouSheBiaoTiHei;
    font-weight: 600;
    color: #fff;
    line-height: 6.15vh;
    width: 14.37vw;
    height: 6.15vh;
    background: #00284d;
    text-align: center;
  }
}
.lavelStyle {
  //   font-size: 1.3vh;
  font-size: 1.48vh;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  //   margin-left: 3vh;
  margin-left: 2vh;
}
.nav /deep/ .el-submenu__title {
  background: #001529;
  line-height: 46px;
  height: 46px;
  //   color: #333!important;
}
.nav /deep/ .el-menu--inline {
  background: #000c17;
}
.nav /deep/ .el-submenu__title {
  //   font-size: 1.3vh;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 200;
  color: #ffffff;
}
.nav /deep/ .el-menu-item {
  //   font-size: 1.3vh;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  color: #ffffff;
  background: #000c17;
}
.nav /deep/ .el-menu-item:hover {
  background: #1a252f !important;
  color: #fff;
}
.nav .el-menu-item:focus,
.el-menu-item:hover {
  background: #1a252f !important;
}
.nav .el-menu-item.is-active {
  background: #1a252f !important;
  color: #7fc1ff;
  //   border-right: 5px solid #1a252f;  边框
}
.tabBox /deep/ .el-tabs__item {
  font-size: 16px !important;
}
.nav /deep/ .el-col-12 {
  //   width: 100%;
  width: 14.5vw;
  overflow: hidden;
}
.nav /deep/ .el-menu {
  background: #001529;
  //   background: #000c17;
}
.nav .el-submenu {
  //   padding-left: 14px;
  box-sizing: border-box;
}
@media (min-width: 900px) and(max-width:1600px) {
  .nav .el-submenu {
    // padding-left: 6px;
  }
}
.conter {
  display: flex;
}
// .nav /deep/ .el-submenu__icon-arrow {
//   position: absolute;
//   top: 50%;
//   left: 20px !important;
// }
// .nav /deep/ .el-submenu__icon-arrow {
//   position: absolute;
//   top: 50%;
//   left: 20px !important;
//   right: auto;
// }

.nav /deep/ .el-submenu__title i {
  //   color: #333;
}
.nav /deep/ tac {
  background: #001529;
}
.rightBoxTwo {
  width: 85vw;
  height: 91vh;
  position: relative;
  //   padding: 2vh 1vh 0 1.5vh;
  padding: 0 1vh 0 1.5vh;
  margin: 2vh 0;
  box-sizing: border-box;
  background: #eee;
  //   overflow: hidden;
}
.tabBox {
  //   height: 10vh;
  //   position: absolute;
  //   top: -28px;
  //   left: 0px;
}
.tabBox /deep/ .el-tabs__header {
  margin: 0;
}
.tabBox /deep/ .el-tabs__item {
  color: #00284d;
  background: #f0f2f5;
  border-radius: 8px 8px 0px 0px;
  margin-right: 0.5vh;
  //   width: 8vw;
  //   text-align: center;
  //   text-overflow: ellipsis;
  //   overflow: hidden;
  //   position: relative;
}
.tabBox /deep/ .el-icon-close {
  position: absolute !important;
  top: 0px !important;
  right: 5px !important;
}
.tabBox /deep/ .el-tabs__item.is-active {
  color: #139660;
}
.tabBox /deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0;
}
.tabBox /deep/ .el-tabs__item {
  padding: 0.8vh 0.5vw;
  line-height: inherit;
  font-size: 1.3vh;
  box-sizing: border-box;
  height: auto;
}
.tabBox /deep/ .el-tabs--card > .el-tabs__header {
  border-bottom: 0px solid #e4e7ed;
}

.leftBox,
.rightBox {
  font-size: 10vh;
}
.leftBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 5vw;
  height: 10vh;
}
.rightBox {
  position: absolute;
  top: 0;
  right: 0;
  width: 5vw;
  height: 10vh;
}
.tabBox .tab_control {
  // width: 80vw;
  width: 75vw;
  //   border: 1px solid red;
  overflow: hidden;
}
.navStyle {
  transition: 1s;
}
.nav /deep/ .el-menu-item {
  //   padding-left: 0px !important;
}
.rightBoxTwo /deep/ .el-tabs__nav-prev {
  left: 0;
  top: 45%;
  transform: translateY(-50%);
  color: #fff;
}
.rightBoxTwo /deep/ .el-tabs__nav-next {
  position: absolute;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  color: #fff;
}
.infoStyle {
  color: #fff;
  cursor: pointer;
  font-size: 1.4vh;
}
.menuTwo {
  //   padding-left: 2vh;
  background: #000c17;
}
.menuTwo /deep/ .el-submenu__title {
  background: #000c17;
  //   color: #333;
  padding-left: calc(6vh + 10px) !important;
  box-sizing: border-box;
}
.menuTwoItem {
  //   padding-left: 8vh !important;
  padding-left: calc(7vh + 10px) !important;
  box-sizing: border-box;
}
.menuTwoItems {
  //   padding-left: 8vh !important;
  padding-left: calc(6vh + 10px) !important;
  box-sizing: border-box;
}
.nav /deep/ .el-submenu .el-menu-item {
  max-width: auto !important;
}
@media (min-width: 900px) and(max-width:1600px) {
  .menuTwoItem {
    //   padding-left: 8vh !important;
    padding-left: calc(8vh + 10px) !important;
    box-sizing: border-box;
  }
  .menuTwo /deep/ .el-submenu__title {
    background: #000c17;
    // color: #333;
    //padding-left: calc(7vh + 10px) !important;
    box-sizing: border-box;
  }
}
.tabBox {
  position: absolute;
  left: 14.37vw;
  bottom: 0;
}
.tabBox /deep/ .el-tabs__nav-prev,
.tabBox /deep/ .el-tabs__nav-next {
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}
.found {
  color: #333;
  text-align: center;
  display: block;
  font-size: 1.5vh;
  margin-top: 1vh;
}
.single {
  margin-left: 6vh;
}
</style>
